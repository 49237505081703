.pimcore_area_content:has(.anchor-nav__item):has(+ .pimcore_area_content > .is-jumpnav-anchor) .anchor-nav__item,
.pimcore_area_content:has(.anchor-nav__item):has(+ .pimcore_area_content > .slide) .anchor-nav__item {
    scroll-margin-top: calc(70px + (55rem / 16));

    @media screen and (min-width: 768px) {
        scroll-margin-top: calc(98px + (90rem / 16));
    }
}

.pimcore_area_content:has(.anchor-nav__item):has(+ .pimcore_area_content > .wysiwyg-cormia__wrapper) .anchor-nav__item,
.pimcore_area_content:has(.anchor-nav__item):has(+ .pimcore_area_content > .slide.img-slide) .anchor-nav__item{
    scroll-margin-top: 70px;

    @media screen and (min-width: 768px) {
        scroll-margin-top: 98px;
    }
}

.anchor-nav__item--highlighted {
    font-family: var(--cormia-font-default-bold);
}

html {
    scroll-behavior: smooth !important;
}