.img-slide__content {
    padding-top: calc(90rem/16);
    padding-bottom: calc(90rem/16);

    @media screen and (min-width: 768px) {
        padding-top: calc(124rem/16);
        padding-bottom: calc(124rem/16);
    }
}


