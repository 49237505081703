.wysiwyg-cormia__wrapper {
    background: linear-gradient(139.18deg, #644C9A -2.64%, #4B3871 105.8%);
    color: #FFF;
    padding-top: calc(94rem/16);
    padding-bottom: calc(94rem/16);
    margin-bottom: calc(94rem/16);
}

.wysiwyg-cormia__wrapper--is-light {
    background: white;
    color: var(--color-primary);
}

.wysiwyg-content {
    font-size: calc(15rem/16);
    text-align: left;
    max-width: 80ch;
}

.wysiwyg-content--two-col {
    display: grid;
    gap: 1.5rem;
    max-width: unset;
    grid-template-columns: 1fr;

    @media screen and (min-width: 768px) {
        grid-template-columns: 45ch 45ch;
    }
}

.wysiwyg-cormia__container {
    display: flex;
    flex-direction: column;
    align-items: center;
}