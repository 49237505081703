.cormia-hero {
    position: relative;
    overflow: hidden;
    height: calc(100svh - 70px);
    background-size: cover;
    background-color: var(--color-primary);

    @media screen and (min-width: 768px) {
        height: calc(100svh - 98px);
    }
}

.cormia-hero__background-shape {
   position: absolute;
    right: 0;
    bottom: 0;
    width: 80vw;
    height: auto;

    rect {
        fill: var(--color-secondary);
    }

    @media screen and (min-width: 768px) {
        bottom: 0;
        width: 72.239vw;
    }
}

.cormia-hero__latin {
    font-size: calc(12rem/16);
    text-transform: uppercase;
    margin-bottom: 3rem;
    letter-spacing: .15625rem;
    font-family: var(--cormia-font-default);
    margin-left: 0.4rem;

    @media screen and (max-width: 767px) {
        font-size: calc(10rem/16);
        margin-bottom: 1rem;

    }
}

.cormia-hero__usp {
    font-size: calc(18rem/16);
    font-family: var(--cormia-font-default);
    margin-bottom: 1rem;
    margin-left: 0.4rem;

    @media screen and (max-width: 767px) {
        font-size: .925rem;
        margin-bottom: .33rem;
    }
}

.cormia-hero__usp .icon {
    font-size: .875rem;

    @media screen and (max-width: 767px) {
        font-size: .7rem;
    }
}

.cormia-hero__title {
    font-size: clamp(3rem, 12vh, 6rem);
    font-family: var(--cormia-font-default-bold);
    display: flex;
    flex-direction: column;
    color: white;
    white-space: nowrap;

    @media screen and (max-width: 767px) {
        font-size: 3rem;
        margin-bottom: 3rem;
    }

    @media screen and (max-width: 992px) and (orientation: landscape) {
        font-size: clamp(1.5rem, 6vmin, 5rem);
        margin-bottom: 1rem;
    }
}

.cormia-hero__title--brand {
    margin-top: 2rem;

    @media screen and (max-width: 992px) and (orientation: landscape) {
        margin-top: .5rem;
    }
}

.cormia-hero__subtitle {
    font-size: calc(25rem/16);
    margin-top: 0rem;
    font-family: var(--cormia-font-default);
    margin-left: 0.4rem;
    color: white;

    @media screen and (max-width: 767px) {
        font-size: 1.5rem;
        margin-top: -1rem;
        margin-bottom: 1rem;
    }
}

.text-primary-gradient,
.text-gradient {
    background: linear-gradient(139.18deg, #644C9A -2.64%, #4B3871 105.8%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text-secondary {
    color: var(--color-secondary);
}

.cormia-hero__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;

    @media screen and (max-width: 767px) {
        justify-content: flex-start;
        padding-top: 6vh;
    }
}

.cormia-hero__cta {
    margin-left: .4rem;
    z-index: 1;
    @media screen and (min-width: 768px) {
        margin-top: 3.5rem;
    }

    @media screen and (max-width: 992px) and (orientation: landscape) {
        margin-top: 0rem;
        font-size: .75rem;
    }
}

.cormia-hero__packaging {
    position: absolute;
    right: -30px;
    bottom: -30px;
}

.cormia-hero__packaging-img {
    width: clamp(50vw, 50vw, calc(880rem / 16));
    object-fit: contain;
    object-position: bottom right;
    height: auto;

    @media screen and (max-width: 767px) {
        height: 50vh;
        width: auto;
        transform: translateX(10%) translateY(10%);
    }

    @media screen and (max-width: 992px) and (orientation: landscape) {
        width: 60vw;
        height: unset;
        transform: translateX(10%) translateY(15%);
    }
}

.page-wrapper {
    background: unset;
}