.container.container {
    max-width: calc((1400rem + 60rem) / 16);
}

.container.container-narrow {
    max-width: calc((830rem + 60rem) / 16);
}

.container.container-xs {
    max-width: calc(888rem / 16);
}