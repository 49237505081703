.main-navbar__container {
    display: grid !important;
    grid-template-columns: 1fr auto 1fr;
    column-gap: 1rem;

    @media screen and (max-width: 767px) {
        grid-template-columns: 1fr auto auto;
    }
}

.main-navbar__dropdown {
    background-color: var(--color-primary-muted);
}

.main-footer__btn.btn-light-grey,
.main-navbar__right .btn-light-grey {
    color: var(--color-primary);
}

@media screen and (max-width: 992px) {
    .mega-nav.mega-nav--level-0 {
        background-color: var(--color-primary-muted);
    }
}


@media screen and (min-width: 993px) {
    .mega-nav__item-content--level-0 {
        color: var(--color-primary-dark);
    }

    .mega-nav__item.is-active>.mega-nav__item-content--level-0 {
        color: var(--color-primary-dark);
    }

    .mega-nav--level-1 {
        background-color: var(--color-primary-muted);
    }
}

.main-navbar__logo-wrapper {
    background: var(--color-primary);
    border-radius: calc(10rem/16);
    justify-self: start;
    padding: 1rem 2rem 1.2rem;
    margin: 1rem;

    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        width: 150px;
        height: calc(60px - 2rem);

        path {
            fill: white;
            stroke: white;
        }
    }
}

.anchor-nav__item-icon {
    display: none;

    @media
        screen and (max-width: 992px) {
        display: inline-block;
    }
}

.nav-toggle__line {
    width: 1rem;
}