@media screen and (min-width: 1400px) {
    .horizontal-teaser--bg .horizontal-teaser__content {
        padding-top: calc(82rem/16);
        padding-bottom: calc(82rem/16);
    }
}

.horizontal-teaser__content .wysiwyg p,
.horizontal-teaser__content .wysiwyg ul,
.horizontal-teaser__content .wysiwyg ol,
.horizontal-teaser__content .wysiwyg a {
    color: var(--color-primary);
}