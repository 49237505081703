.btn {
    font-family: var(--cormia-font-default);
    font-size: calc(12rem/16);
}

.btn-light-grey {
    color: var(--color-primary);
}

.btn-secondary {
    color: var(--color-text-default);
}