.cormia-section-title {
    font-size: calc(34rem/16);
    margin-bottom: 1.5rem;
}

.cormia-section-toptitle {
    text-transform: uppercase;
    font-size: calc(12rem/16);
    margin-bottom: .5rem;
    letter-spacing: .15625rem;
}

@media screen and (min-width: 1200px) {
    .cormia-section-title {
        font-size: calc(34rem/16);
        margin-bottom: 1.5rem;
    }

    .cormia-section-toptitle {
        text-transform: uppercase;
        font-size: calc(12rem/16);
        margin-bottom: .5rem;
        letter-spacing: .15625rem;
    }
}

.text-color-default {
    color: #202628;
}