:root {
    --color-primary: #644C9A;
    --color-primary-muted: #e0dce7ff;
    --color-secondary: var(--color-cormia-green);
    --color-text-default: var(--color-cormia-text);
    --color-text-muted: var(--color-dark-grey);
    --color-bg: #EDEBF0;

    --color-default: #202628;
    --color-white: #FFF;
    --color-light-grey: #F3F3F3;
    --color-grey: #DEDEDE;
    --color-dark-grey: #868686;
    --color-dark: #343A40; /* todo */

    --color-mint: #cce6d9;
    --color-success: #28A745;
    --color-info: #FF8022;
    --color-warning: #ffc107;
    --color-info-blue: #17A2B8;
    --color-danger: #DC3545;

    --color-cormia-green: #7DC4A3;
    --color-cormia-gradient-primary-dark: var(--color-primary-dark);
    --color-cormia-gradient-primary-light: var(--color-primary);

    --color-primary-contrast: #FFF;
    --color-secondary-contrast: #FFF;
    --color-success-contrast: #FFF;
    --color-info-contrast: #fff;
    --color-warning-contrast: var(--color-text-default);
    --color-danger-contrast: #FFF;
    --color-light-contrast: var(--color-text-default);
    --color-dark-contrast: #FFF;
    --color-white-contrast: var(--color-text-default);
    --color-default-contrast: #FFF;

    --color-primary-dark: #4B3871;
    --color-secondary-dark: #004320;
    --color-success-dark: #12862B;
    --color-info-dark: #E56100;
    --color-warning-dark: #967102;
    --color-danger-dark: #cc2333;
    --color-light-dark: #e4e8ec;
    --color-dark-dark: #14191E;
    --color-white-dark: #F8F9FA;
    --color-default-dark: #1f232a;

    --color-primary-light: #644C9A;
    --color-secondary-light: #DDEFE5;
    --color-success-light: #1EE048;
    --color-info-light: #ff8c33;
    --color-warning-light: #FBBD03;
    --color-danger-light: #e15260;
    --color-light-light: #fff;
    --color-dark-light: #687F96;
    --color-white-light: #FFF;
    --color-default-light: #3b4552;

    --font-default: "GorditaRegular", sans-serif;
    --font-default-weight: 400;
    --font-default-bold: "GorditaBold", sans-serif;
    --font-default-bold-weight: 400;
    //--font-default-medium: "Roboto-Medium", sans-serif;
    //--font-default-medium-weight: 400;

    --font-size-default: calc(14rem / 16);

    --cormia-font-default: 'NewRubrikEdge-Regular';
    --cormia-font-default-bold: 'NewRubrikEdge-Bold';
    --font-default: var(--cormia-font-default);
    --font-default-bold: var(--cormia-font-default-bold);
}

.bg-secondary {
    background-color: var(--color-primary-muted) !important;
}