.quote-area {
    background-color: var(--color-primary-muted);
    padding-top: calc(70rem/16);
    padding-bottom: calc(70rem/16);
}

.quotation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.quotation__text {
    max-width: 32ch;
    text-align: center;
    margin-bottom: 0;

    font-size: 1.5rem;
    line-height: 1.33333;

    @media screen and (min-width: 768px) {
        font-size: calc(36rem/16);
    }
}

.quotation:before {
    font-size: 1.375rem;

    @media screen and (min-width: 768px) {
        font-size: 1.875rem;
    }
}

