.nav-toggle--microsite {
    display: none;
}

@media screen and (max-width: 992px) {
    .mega-nav__list {
        display: none;
    }

    .nav-toggle--microsite {
        display: unset;
    }

    .mega-nav--level-0 {
        background-color: var(--color-primary-muted);
    }

    .mega-nav__list--level-0:after {
        transform: translateY(50%);
    }

    .mega-nav__list.mega-nav__list--level-0 {
        display: flex;
        flex-direction: column;
    }

    .main-navbar__container {
        grid-template-columns: 1fr auto auto;
    }

    .nav-toggle__line {
        width: 16px;
        height: 3px;
    }
}

